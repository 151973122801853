import React from 'react';
import { When } from 'react-if';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Gap, LoaderDots, SmallButton, Text } from '@reservamos/elements';
import siempreplus from 'images/brands-unique/gfa/siempreplus.png';
import costapass from 'images/costapass/costapass-logo.svg';
import travelpass from 'images/travelpass/travelpass-logo.png';
import doters from 'images/doters/logo.svg';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import usePurchase from '../../hooks/store/usePurchase';
import useLoyaltyPrograms from '../../loyalty/context/useLoyaltyPrograms';
import { handleLoyaltySelection } from '../../actions/loyalty';

const loyaltyProgram = {
  siempreplus: {
    logo: siempreplus,
    name: 'Siempre Plus',
  },
  costapass: {
    name: 'CostaPass',
    logo: costapass,
  },
  travelpass: {
    name: 'TravelPass',
    logo: travelpass,
  },
  doters: {
    logo: doters,
    name: 'Doters',
  },
};

const LoyaltyHolder = ({ hideLabel, loyaltyHolderName }) => {
  const { t } = useTranslation('passengers');
  const { availableWallets, isExchange, isUpdatingWalletType } = usePurchase();
  const { loggedInWithMultiplePrograms } = useLoyaltyPrograms();
  const dispatch = useDispatch();
  const { travelpassLoyaltyConfig } = useWhitelabelEnvs();
  const prefix = travelpassLoyaltyConfig.prefix || 'costapass';

  if (isExchange) return null;

  const onChangeLoyalty = () => {
    dispatch(
      handleLoyaltySelection({
        loyaltyProgram: null,
        updatePurchase: true,
        needsUnlockSeats: true,
      }),
    );
  };

  const showChangeButton = loggedInWithMultiplePrograms && availableWallets.length > 1;
  const loyaltyLogo =
    loyaltyHolderName === 'travelpass'
      ? loyaltyProgram[prefix].logo
      : loyaltyProgram[loyaltyHolderName].logo;

  const loyaltyAlt =
    loyaltyHolderName === 'travelpass'
      ? loyaltyProgram[prefix].name
      : loyaltyProgram[loyaltyHolderName].name;

  return (
    <Gap alignItems="center" responsiveColumnGap="XS" responsiveRowGap="XS">
      {hideLabel || (
        <Text mobileSize="S" color="grayStrong">
          {t('label.holder')}
        </Text>
      )}
      <Box
        bgColor="primary"
        alphaBg="XS"
        borderRadius="M"
        paddingHorizontal="XS"
        paddingVertical="XS"
      >
        <Gap columnGap="S" alignItems="center">
          <When condition={!isUpdatingWalletType}>
            <img src={loyaltyLogo} alt={loyaltyAlt} height="25" width="125" />
            {showChangeButton && (
              <SmallButton
                onClick={onChangeLoyalty}
                text={t('label.change')}
                mobileSize="S"
                color="primary"
                fontWeight="regular"
              />
            )}
          </When>
          <When condition={isUpdatingWalletType && showChangeButton}>
            <LoaderDots isAccent />
          </When>
        </Gap>
      </Box>
    </Gap>
  );
};

LoyaltyHolder.propTypes = {
  loyaltyHolderName: PropTypes.oneOf(['Siempre Plus', 'doters']).isRequired,
  hideLabel: PropTypes.bool,
};

LoyaltyHolder.defaultProps = {
  hideLabel: false,
};

export default LoyaltyHolder;
