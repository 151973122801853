/**
 * Normalizes a given string based on the provided regular expression.
 *
 * @param {string} regex - The regular expression pattern to use for normalization.
 * @param {string} string - The input string to be normalized.
 * @returns {string} - A string containing all matches of the regular expression in the input string.
 */
const normalizeRegex = (regex, string) => {
  try {
    const regExp = regex ? new RegExp(regex, 'g') : null;
    return string.replace(regExp || /\s/g, '');
  } catch (e) {

    return string;
  }
};

export default normalizeRegex;
